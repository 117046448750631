import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import FlagAD from '@/assets/img/flag/flag_ad.png';
import FlagAE from '@/assets/img/flag/flag_ae.png';
import FlagAU from '@/assets/img/flag/flag_au.png';
import FlagCA from '@/assets/img/flag/flag_ca.png';
import FlagCH from '@/assets/img/flag/flag_ch.png';
import FlagCN from '@/assets/img/flag/flag_cn.png';
import FlagDE from '@/assets/img/flag/flag_de.png';
import FlagDK from '@/assets/img/flag/flag_dk.png';
import FlagFR from '@/assets/img/flag/flag_fr.png';
import FlagHK from '@/assets/img/flag/flag_hk.png';
import FlagIN from '@/assets/img/flag/flag_in.png';
import FlagJP from '@/assets/img/flag/flag_jp.png';
import FlagKO from '@/assets/img/flag/flag_ko.png';
import FlagLG from '@/assets/img/flag/flag_lg.png';
import FlagMN from '@/assets/img/flag/flag_mn.png';
import FlagNL from '@/assets/img/flag/flag_nl.png';
import FlagNZ from '@/assets/img/flag/flag_nz.png';
import FlagSE from '@/assets/img/flag/flag_se.png';
import FlagUS from '@/assets/img/flag/flag_us.png';
import Outcome1 from '@/assets/img/for-every-child/02-un-0506086@2x.png';
import Outcome1M from '@/assets/img/for-every-child/02-un-0506086@2x.png';
import Outcome2 from '@/assets/img/for-every-child/03-un-0400127@2x.png';
import Outcome2M from '@/assets/img/for-every-child/03-un-0400127@2x.png';
import Outcome3M from '@/assets/img/for-every-child/06-un-0385787@2x.png';
import Outcome3 from '@/assets/img/for-every-child/06-un-0385787@2x.png';
import Outcome4 from '@/assets/img/for-every-child/08-uni-319343@2x.png';
import Outcome4M from '@/assets/img/for-every-child/08-uni-319343@2x.png';
import Outcome5 from '@/assets/img/for-every-child/09-un-0462735@2x.png';
import Outcome5M from '@/assets/img/for-every-child/09-un-0462735@2x.png';
import Partner1 from '@/assets/img/for-every-child/partner1.png';
import Partner2 from '@/assets/img/for-every-child/partner2.png';
import Partner3 from '@/assets/img/for-every-child/partner3.png';
import Partner4 from '@/assets/img/for-every-child/partner4.png';
import Partner5 from '@/assets/img/for-every-child/partner5.png';
import Partner6 from '@/assets/img/for-every-child/partner6.png';
import Partner7 from '@/assets/img/for-every-child/partner7.png';
import Partner8 from '@/assets/img/for-every-child/partner8.png';
import Activity1 from '@/assets/img/for-every-child/schoolsforasia_activity1@2x.png';
import Activity2 from '@/assets/img/for-every-child/schoolsforasia_activity2@2x.png';
import Activity3 from '@/assets/img/for-every-child/schoolsforasia_activity3@2x.png';
import BusinessObj1 from '@/assets/img/for-every-child/schoolsforasia_business1@2x.png';
import BusinessObj2 from '@/assets/img/for-every-child/schoolsforasia_business2@2x.png';
import BusinessObj3 from '@/assets/img/for-every-child/schoolsforasia_business3@2x.png';
import BusinessObj4 from '@/assets/img/for-every-child/schoolsforasia_business4@2x.png';
import BusinessObj5 from '@/assets/img/for-every-child/schoolsforasia_business5@2x.png';
import Map2020 from '@/assets/img/for-every-child/schoolsforasia_map@2x.png';
import Map2020M from '@/assets/img/for-every-child/schoolsforasia_mapM@2x.png';
import Obj1 from '@/assets/img/for-every-child/schoolsforasia_obj1@2x.png';
import Obj2 from '@/assets/img/for-every-child/schoolsforasia_obj2@2x.png';
import Obj3 from '@/assets/img/for-every-child/schoolsforasia_obj3@2x.png';
import Obj4 from '@/assets/img/for-every-child/schoolsforasia_obj4@2x.png';
// import Partner1 from '@/assets/img/no_img1@2x.png';
import Button from '@/components/Button';
import { CampaignData } from '@/components/Card/CampaignCard';
import CampaignCard2 from '@/components/Card/CampaignCard2';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import { SectionNews } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import Partner from '@/components/Partner';
import { ProcessList } from '@/components/ProcessList';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const businessData = [
  {
    id: 1,
    img: BusinessObj1,
    title: `영유아 교육 <small>Early learning</small>`,
    desc: [
      {
        dec: `영유아 교육은 매우 중요합니다. 영유아 시절 받은 교육은 어린이가 자라 자신의 잠재력을 전부 발휘할 수 있도록 돕습니다. 유니세프는 미취학 어린이를 위한 학교를 건설하고, 교사 교육을 하며, 영유아 교육에 대한 대중 인식을 높입니다. `,
      },
    ],
  },
  {
    id: 2,
    img: BusinessObj2,
    title: `양질의 교육 <small>Quality Learning</small>`,
    desc: [
      {
        dec: `학교 출석률이 양질의 교육을 보장하지는 않습니다. 학교를 다녀도 읽기, 쓰기, 계산하기 등 기본 능력을 익힐 수 없다면, 어린이는 제대로 성장할 수 없습니다. 유니세프는 아시아 어린이가 양질의 교육을 받을 수 있도록 잘 설계된 교육 정책, 커리큘럼 수립을 도우며, 교사 훈련과 좋은 교자재를 지원합니다. `,
      },
    ],
  },
  {
    id: 3,
    img: BusinessObj3,
    title: `청소년 교육 <small>Adolescent learning and skills</small>`,
    desc: [
      {
        dec: `청소년은 성인의 삶을 준비하는 시기입니다. 이 시기에 청소년이 자신의 삶을 주체적으로 꾸려 나갈 수 있도록 돕는 것은 매우 중요합니다. 유니세프는 적성 개발 교육, 기술 및 직업 교육, 역량 강화 프로그램으로 아시아 청소년을 지원합니다.`,
      },
    ],
  },
  {
    id: 4,
    img: BusinessObj4,
    title: `긴급재난, 기후위기 교육 <small>Education in emergencies and climate change</small>`,
    desc: [
      {
        dec: `아시아의 많은 어린이가 기후변화 혹은 자연 재해의 피해를 입고 있습니다. 이런 상황에서도 어린이는 기본 권리인 학습권을 보장받을 수 있어야 합니다. 유니세프는 아시아 각 정부와 긴급 교육 시스템을 수립하고, 재난에도 튼튼한 학교를 건설하며, 원격 교육 프로그램을 개발합니다. `,
      },
    ],
  },
  {
    id: 5,
    img: BusinessObj5,
    title: `공평한 교육 시스템 및 정책 강화 <small>Systems strengthening and governance</small>`,
    desc: [
      {
        dec: `돈이 없어서, 장애가 있어서, 여자라는 이유로 교육의 혜택을 받지 못하는 어린이가 많습니다. 취약 계층을 포함한 모든 어린이는 동등한 교육을 받아야 합니다. 유니세프는 유엔아동권리 정신에 입각하여, 각 정부가 모든 어린이에게 공평한 교육 서비스를 제공할 수 있도록 촉구합니다.`,
      },
    ],
  },
];

const ActivityData = [
  {
    id: 1,
    image: Activity1,
    title: `영유아 교육 시설 건립`,
  },
  {
    id: 2,
    image: Activity2,
    title: `식수 및 위생 시설 제공`,
  },
  {
    id: 3,
    image: Activity3,
    title: `교육 서비스 지원`,
  },
];

const itemData = [
  {
    id: 1,
    img: Obj1,
    title: `연간 1억 원 이상`,
    desc: [
      {
        dec: `베트남 어린이가 마음껏 책을 읽을 수 있는 아동 친화 도서관 리모델링 `,
      },
    ],
  },
  {
    id: 2,
    img: Obj2,
    title: `연간 5천만 원 이상`,
    desc: [
      {
        dec: `가정 형편이 어려워 교육을 받기 힘든 몽골 어린이 4,500명의 1년 중학교 과정 수료 지원`,
      },
    ],
  },
  {
    id: 3,
    img: Obj3,
    title: `연간 3천만 원 이상`,
    desc: [
      {
        dec: `위생시설이 부족한 필리핀 학생을 위한 깨끗한 학교 화장실 6동 건립`,
      },
    ],
  },
  {
    id: 4,
    img: Obj4,
    title: `연간 1천만 원 이상`,
    desc: [
      {
        dec: `1,330명이 긴급상황에도 공부할 수 있는 상자학교 (School-in-a-Box) 33개지원`,
      },
    ],
  },
];

const partnerData = [
  {
    id: 1,
    img: Partner1,
    title: `사랑의 본부`,
  },
  {
    id: 2,
    img: Partner2,
    title: `SM엔터테인먼트`,
  },
  {
    id: 3,
    img: Partner3,
    title: `롯데자이언츠`,
  },
  {
    id: 4,
    img: Partner4,
    title: `DFD 패션그룹`,
  },
  {
    id: 5,
    img: Partner5,
    title: `이마트`,
  },
  {
    id: 6,
    img: Partner6,
    title: `SPAO`,
  },
  {
    id: 7,
    img: Partner7,
    title: `롯데호텔`,
  },
  {
    id: 8,
    img: Partner8,
    title: `레드덕`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `기업후원팀`,
    tel: `02-721-5121~5`,
    email: `csr@unicef.or.kr`,
  },
];

const ArcicleContainer = styled.div`
  ${SectionHeader} {
    padding-bottom: 96px !important;

    ${breakpoint(`tablet`)} {
      padding-bottom: 72px !important;
    }
  }

  h3 {
    position: relative;
    z-index: 1;
  }
  .map-container {
    margin-bottom: 150px;
    padding-bottom: 783px;
    position: relative;

    .map {
      width: 1253px;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    ${breakpoint(1160)} {
      padding-bottom: 67.5vw;
      .map {
        width: 108.02vw;
      }
    }
    ${breakpoint(`tablet`)} {
      margin-bottom: 60px;
    }
    ${breakpoint(`mobile`)} {
      padding-bottom: 91.72vw;
      .map {
        width: auto;
        left: -20px;
        right: -20px;
      }
    }
  }
  .ProcessContainer {
    dd {
      padding-top: 64px;

      h3 {
        margin-top: 0;
        ${Tit} {
          font-size: 24px;

          ${breakpoint(1160)} {
            font-size: 2.07vw;
          }
          ${breakpoint(`tablet`)} {
            font-size: 18px;
          }
        }

        small {
          display: block;
          color: #828385;
          font-size: 16px;
          line-height: 2;
          font-weight: 400;
          text-align: center;
        }
      }
    }
    .list-item {
      width: 50%;
      margin-bottom: 160px;

      &:nth-child(3),
      &:last-child {
        margin-bottom: 0;
      }
      .dec-ul {
        max-width: 408px;
        margin: 0 auto;
        margin-top: 16px;
        text-align: left;
        line-height: 2;
      }
    }

    ${breakpoint(`tablet`)} {
      dd {
        padding-top: 48px;
      }
      .img-wrap {
        max-width: 160px;
      }
      .list-item {
        width: 100%;
        margin-bottom: 72px;

        &:nth-child(3) {
          margin-bottom: 72px;
        }

        .dec-ul {
          max-width: 100%;
        }
      }
      dd {
        padding-top: 48px;
      }
    }

    ${breakpoint(`mobile`)} {
      .list-item {
        .dec-ul {
          max-width: 100%;
          padding: 0;
        }
      }
    }
  }
`;
const SectionTop = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 240px;

    ${breakpoint(`tablet`)} {
      padding-bottom: 120px;
    }
  }
`;

const SectionActivity = styled(Section)`
  .swiper-container {
    margin: 0 -16px;
    padding-bottom: 0;

    .swiper-slide {
      width: 33.33%;
      padding: 0 16px;
      margin-right: 0;

      .img-box {
        padding-top: 100%;
      }
      .dec-wrap {
        padding-top: 32px;
      }
      ${Tit} {
        color: #1cabe2;
        padding: 0;
        text-align: center;
        display: block;
        width: 100%;
      }
      .yuotube-wrap {
        display: none;
      }
    }
    .swiper-scrollbar,
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    ${breakpoint(`mobile`)} {
      padding-bottom: 72px;
      .swiper-slide {
        width: 100%;
      }
      .swiper-scrollbar {
        display: block;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 32px;
    }
    .swiper-container {
      .swiper-slide {
        .dec-wrap {
          padding-top: 24px;
        }
      }
    }
  }
`;

const SectionScale = styled(Section)`
  table {
    margin-top: 0;
    .col1 {
      width: 25%;
    }
    .col2,
    .col3 {
      width: 37.5%;
    }
    td {
      height: 60px;
    }
    tbody {
      td {
        padding: 0 20px;
      }
      th {
        height: 60px;
        padding: 0 20px;
        background: #e2f0f6;
        text-align: center;
      }
      .bold {
        height: 60px;
        padding: 0 20px;
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .flag {
    padding-left: 28px;
    position: relative;
    display: inline-block;
    letter-spacing: -0.8px;

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.au::before {
      background-image: url(${FlagAU});
    }
    &.ch::before {
      background-image: url(${FlagCH});
    }
    &.ko::before {
      background-image: url(${FlagKO});
    }
    &.de::before {
      background-image: url(${FlagDE});
    }
    &.fr::before {
      background-image: url(${FlagFR});
    }
    &.hk::before {
      background-image: url(${FlagHK});
    }
    &.in::before {
      background-image: url(${FlagIN});
    }
    &.nz::before {
      background-image: url(${FlagNZ});
    }
    &.se::before {
      background-image: url(${FlagSE});
    }
    &.us::before {
      background-image: url(${FlagUS});
    }
    &.mn::before {
      background-image: url(${FlagMN});
    }
    &.cn::before {
      background-image: url(${FlagCN});
    }
    &.jp::before {
      background-image: url(${FlagJP});
    }
    &.dk::before {
      background-image: url(${FlagDK});
    }
    &.ad::before {
      background-image: url(${FlagAD});
    }
    &.ae::before {
      background-image: url(${FlagAE});
    }
    &.lg::before {
      background-image: url(${FlagLG});
    }
    &.nl::before {
      background-image: url(${FlagNL});
    }
    &.ca::before {
      background-image: url(${FlagCA});
    }
  }
  ${breakpoint(`tablet`)} {
    .flag {
      padding-left: 18px;
      &::before {
        width: 12px;
        height: 12px;
      }
    }
    table {
      .col1 {
        width: 60px;
      }
      .col2 {
        width: calc(100% - 150px);
      }
      .col3 {
        width: 90px;
      }
      td,
      th {
        height: 48px;
      }
      tbody {
        td,
        th {
          height: 48px;
          padding: 0 4px;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    table {
      .col1 {
        width: 40px;
      }
      .col3 {
        width: 82px;
      }
    }
  }
`;

const SectionOutcome = styled(Section)`
  .list-ul {
    max-width: 960px;
    margin: 0 auto;
    text-align: center;

    & > li {
      display: inline-block;
      margin-bottom: 96px;
      position: relative;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }

      ${Tit} {
        padding-bottom: 32px;
        border-bottom: 1px solid #b7b8ba;
        width: 60%;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &:nth-child(2n + 0) {
        text-align: right;
        ${Tit} {
          left: auto;
          right: 0;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      & > li {
        margin-bottom: 64px;

        ${Tit} {
          margin-top: 16px;
          text-align: left !important;
          padding-bottom: 16px;
          width: 100%;
          position: relative;
        }
      }
    }
  }
`;

const SectionTogether = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 86px !important;
  }

  .col-container {
    margin-top: 62px;
    padding-bottom: 96px;
    margin-bottom: 96px;
    border-bottom: 1px solid #e5e6e8;
  }
  .col-flex {
    flex-wrap: wrap;
    margin: 0 -16px;

    align-items: flex-start;

    dl {
      width: 50%;
      padding: 0 16px;
      dt {
        padding-bottom: 16px;
      }
    }
  }
  ${ProcessList} {
    .dec-ul {
      max-width: 250px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }

  ${breakpoint(`tablet`)} {
    .col-container {
      margin-top: 54px;
      padding-bottom: 72px;
      margin-bottom: 72px;
    }
    .col-flex {
      dl {
        width: 100%;
        margin-bottom: 44px;

        &:last-child {
          margin-bottom: 0;
        }
        dt {
          padding-bottom: 16px;
        }
        dd {
          .blit-list {
            & > li {
              margin-bottom: 6px;

              &:last-child {
                margin-bottom: 0;
              }
            }
            strong {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }

    ${ProcessList} {
      .img-wrap {
        max-width: 160px !important;
      }
      .list-item {
        margin-bottom: 72px;

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const SectionPartner = styled(Section)`
  padding-bottom: 23px !important;

  ${breakpoint(`tablet`)} {
    .btn-flex {
      margin-bottom: 60px !important;
    }
  }
`;

const SectionReport = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px !important;
  }

  .report {
    display: none;
  }
  .btn-flex {
    flex-wrap: wrap;
    margin: 0 -16px;
    margin-bottom: 240px;

    & > li {
      width: 50%;
      padding: 0 16px;
    }
  }

  ${breakpoint(`tablet`)} {
    .btn-flex {
      margin-bottom: 120px;
    }
  }
  ${breakpoint(`mobile`)} {
    .btn-flex {
      & > li {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const SchoolsForAsia: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A019'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="스쿨스 포 아시아"
      description="for every child, an education"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  아시아에 <br />
                  희망의 학교를 선물하세요
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                한국은 유니세프 역사상 유일하게 ‘도움 받던 나라에서 도움 주는
                나라’로 탈바꿈한 나라입니다. 한국전쟁 직후 세상에서 가장 가난한
                나라 가운데 하나였던 한국이 이처럼 발전한 데에는 교육의 힘이
                큽니다. 교육은 어린이 한 명의 삶을 개선시킬 뿐 아니라 국가와
                사회 전체를 변화시키는 엄청난 힘을 지니기 때문입니다. <br />
                유니세프는 2004년부터 ‘Schools for Africa’ 캠페인을 통해
                아프리카의 교육환경을 성공적으로 개선해왔으며 이를 바탕으로
                2012년부터 ‘Schools for Asia-아시아에 희망의 학교를 선물하세요’
                캠페인을 시작해 아시아 어린이의 교육환경을 개선하고 있습니다.
                유니세프한국위원회 중점사업이기도 한 ‘Schools for Asia’는 이제
                아시아 어린이의 희망이 됐습니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <ArcicleContainer>
            <article className="map-container">
              <h3>
                <Tit size="s1">사업 대상국(6개국)</Tit>
              </h3>
              <div className="map">
                <Image pcSrc={Map2020} mobileSrc={Map2020M} />
              </div>
            </article>

            <article className="ProcessContainer">
              <SectionHeader className="with-desc">
                <h3>
                  <Tit size="s1">사업 영역</Tit>
                </h3>
              </SectionHeader>
              <ProcessList itemData={businessData} />
            </article>
          </ArcicleContainer>
        </Container>
      </SectionTop>

      <SectionActivity className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">한국위원회 주요 활동</Tit>
            </h2>
            <p className="header-dec">
              유니세프 ‘Schools for Asia’ 캠페인 출범에 가장 큰 기여를 한
              유니세프한국위원회는 2012년 캠페인이 시작된 이래{` `}
              <strong>2022년까지 총 3,000만 달러</strong>에 이르는 기부금을
              지원했습니다. 이로써 유니세프한국위원회는 ‘Schools for Asia’를
              위해 가장 많은 기금을 조성한 국가위원회 중 하나가 됐습니다.
            </p>
          </SectionHeader>

          <CampaignCard2 campaigns={ActivityData} allowTouchMove={false} />
        </Container>
      </SectionActivity>

      <SectionScale className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">지원 규모</Tit>
            </h2>
            <p className="header-dec">
              유니세프는 ‘Schools for Asia’ 캠페인이 시작된 2012년부터{` `}
              <strong>2022년까지 1억 2천만 달러 이상</strong>을 모금해 6개
              나라의 교육 접근성 확대 및 교육의 질 개선에 큰 도움을 주고
              있습니다. ‘Schools for Asia’ 캠페인에는 각 유니세프 국가위원회 뿐
              아니라 전 세계에서 불우한 친구를 돕겠다며 기부에 참여한 어린이들도
              포함돼 있습니다. 유니세프는 소외된 아시아 어린이에게 교육 기회를
              제공하고자 도움을 아끼지 않은 모든 후원자님께 진심으로
              감사드립니다.
            </p>
          </SectionHeader>
          <table>
            <colgroup>
              <col className="col1" />
              <col className="col2" />
              <col className="col3" />
            </colgroup>
            <thead>
              <tr>
                <th>순위</th>
                <th>위원회</th>
                <th>지원금액</th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <th>1</th>
                <th>
                  <p className="flag ko">유니세프한국위원회</p>
                </th>
                <th>
                  <p className="price">$ 6,541,521</p>
                </th>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <p className="flag nz">유니세프뉴질랜드위원회</p>
                </td>
                <td>
                  <p className="price">$ 819,144</p>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <p className="flag lg">유니세프룩셈부르크위원회</p>
                </td>
                <td>
                  <p className="price">$ 473,380</p>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <p className="flag fr">유니세프프랑스위원회</p>
                </td>
                <td>
                  <p className="price">$ 445,394</p>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <p className="flag au">유니세프호주위원회</p>
                </td>
                <td>
                  <p className="price">$ 345,302</p>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <p className="flag ca">유니세프캐나다위원회</p>
                </td>
                <td>
                  <p className="price">$ 309,978</p>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <p className="flag ch">유니세프스위스위원회</p>
                </td>
                <td>
                  <p className="price">$ 251,610</p>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <p className="flag ae">유니세프아랍에미리트국가사무소</p>
                </td>
                <td>
                  <p className="price">$ 161,000</p>
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  <p className="flag de">유니세프독일위원회</p>
                </td>
                <td>
                  <p className="price">$ 156,715</p>
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>
                  <p className="flag nl">유니세프네덜란드위원회</p>
                </td>
                <td>
                  <p className="price">$ 119,863</p>
                </td>
              </tr>
            </tbody>
          </table>
        </Container>
      </SectionScale>

      <SectionOutcome className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">핵심 성과</Tit>
            </h2>
            <p className="header-dec">
              Schools for Asia 캠페인과 관련 기구들의 협업으로 다음의 성과를
              달성했습니다 (2022년 기준).
            </p>
          </SectionHeader>
          <ul className="list-ul">
            <li>
              <Image pcSrc={Outcome1} mobileSrc={Outcome1M} />
              <Tit size="s4">
                [라오스] 교사진과 학생 2만 5,000여 명
                <br />
                에게 디지털 학습 플랫폼 확대
              </Tit>
            </li>
            <li>
              <Image pcSrc={Outcome2} mobileSrc={Outcome2M} />
              <Tit size="s4">
                [몽골] 어린이 약 13만 명에게
                <br />
                질과 접근성 좋은 방과 후 활동 프로그램 제공
              </Tit>
            </li>
            <li>
              <Image pcSrc={Outcome3} mobileSrc={Outcome3M} />
              <Tit size="s4">
                [베트남] 미취학 어린이 430만 명에게
                <br />
                새로운 영유아 교육 과정 제공
              </Tit>
            </li>
            <li>
              <Image pcSrc={Outcome4} mobileSrc={Outcome4M} />
              <Tit size="s4">
                [필리핀] 코로나19로 낮아진 교육수준 회복을 위해
                <br />
                어린이 26만 명 읽기평가 시행 및 통합 읽기 프로그램 도입
              </Tit>
            </li>
            <li>
              <Image pcSrc={Outcome5} mobileSrc={Outcome5M} />
              <Tit size="s4">
                [인도] 유니세프가 지원하는 초등학교에서
                <br />
                글자를 배우는 인도 어린이들
              </Tit>
            </li>
          </ul>
        </Container>
      </SectionOutcome>

      <SectionTogether className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">함께하는 방법</Tit>
            </h2>
          </SectionHeader>
          <article>
            <h3>
              <Tit size="s1-5" color="sky" weight="normal">
                후원 방법
              </Tit>
            </h3>

            <div className="col-container">
              <div className="col-flex flex">
                <dl>
                  <dt>
                    <Tit size="s3-1" color="sky">
                      지정 기탁 사업
                    </Tit>
                  </dt>
                  <dd>
                    <ul className="blit-list">
                      <li>
                        <strong>
                          특정 국가 및 사업 분야를 지정해 어린이 교육 사업에
                          기금 후원
                        </strong>
                      </li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <Tit size="s3-1" color="sky">
                      기본 조건
                    </Tit>
                  </dt>
                  <dd>
                    <ul className="blit-list">
                      <li>
                        <strong>기금 : 연 10~20만 달러</strong>
                        (한화 1억 1천만~2억 2천만 원, 환율 1달러 : 1,100원 기준)
                      </li>
                      <li>
                        <strong>기간 : 최소 3년 협약</strong>
                      </li>
                      <li>
                        <strong>지정국가 : 아시아 유니세프 활동 국가</strong>
                        (국가별·사업별 금액 상이할 수 있음)
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
          </article>
          <ProcessList itemData={itemData} />
        </Container>
      </SectionTogether>

      <SectionPartner className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">함께하는 기업 · 단체</Tit>
            </h2>
          </SectionHeader>
          <Partner itemData={partnerData} />
        </Container>
      </SectionPartner>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A019" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
          {/* <CampaignCard campaigns={thumbs} overflow /> */}
          {/* 주석 */}
        </Container>
      </SectionNews>

      <SectionReport className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc report">
            <h2>
              <Tit size="s3-1">Schools for Asia 성과보고서</Tit>
            </h2>
          </SectionHeader>
          <article>
            <ul className="btn-flex flex">
              <li className="report">
                <Button
                  outline
                  full
                  ico="down"
                  onClick={() =>
                    window.open(
                      `${process.env.BACKEND_URL}/unicef/api/ebook/view?path=/ebook/unicef-publications/756/`,
                    )
                  }
                >
                  <span className="ico">전문</span>
                </Button>
              </li>
              <li className="report">
                <Button
                  outline
                  full
                  ico="down"
                  onClick={() =>
                    window.open(
                      `${process.env.BACKEND_URL}/unicef/api/ebook/view?path=/ebook/schools-for-asia/2018/`,
                    )
                  }
                >
                  <span className="ico">요약본</span>
                </Button>
              </li>
            </ul>
          </article>
          <article>
            <ContactUs itemData={contactData} />
          </article>
        </Container>
      </SectionReport>
    </LayoutWithTitle>
  );
};

export default SchoolsForAsia;
